/* Fontawesome 4.7 */
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
/* XEION CDN */
@import url("http://cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css");

/* Web Fonts - Source Sans Pro */
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");
/* font-family: 'Source Sans Pro', sans-serif; */

/* Web Fonts - NoonNoo */
@font-face {
  font-family: "NEXON Lv2 Gothic";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv2 Gothic.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Slick slider CSS */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.modal-auto {
  justify-content: center;
}
.modal-content {
  width: 60vw;
  @media (max-width: 780px) {
    width: 80vw !important;
    height: auto;
    max-width: 90vw !important;
  }
}
#event-modal-title {
  font-size: 1rem !important;
}

.dropdown-menu.show {
  max-height : 17rem;
  overflow-y: auto;
}
